<template>
  <v-dialog
    v-model="dialog"
    :disabled="!edit"
    width="500"
    v-if="$store.getters['auth/hasUserImage']"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="profile_img">
        <v-img :src="$store.getters['auth/userImage']" class="pointer">
          <v-icon v-if="edit" id="camera" size="40" color="primary"
            >$camera</v-icon
          >
        </v-img>
        <v-btn
          icon
          color="red"
          id="delete"
          v-if="edit"
          @click.stop="deleteFile()"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card id="cardPerfil" class="px-5">
      <v-card-title class="headline"
        ><span> Subir imagen de perfil </span>
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <file-upload
          class="pt-4 pointer"
          style="width: 100%; min-height: 100px"
          :multiple="false"
          :drop="true"
          :drop-directory="false"
          accept="image/*"
          v-model="file"
          ref="upload"
          @input-file="inputFile"
        >
          <div
            style="
              border: dashed var(--v-primary-base) 3px;
              border-radius: 10px;
              min-height: 50px;
              text-align: center;
              line-height: 50px;
              color: var(--v-primary-base);
            "
          >
            <div v-if="file.length">
              <v-chip>{{ file[0].name }}</v-chip>
            </div>
            <div v-else width="100%">
              <h4 class="message" style="margin: auto">
                <v-icon color="primary" size="14">$clip</v-icon>
                {{ $t("designs.drop") }}
              </h4>
            </div>

            <div class="example-btn"></div>
          </div>
        </file-upload>
      </v-card-text>
    </v-card>
  </v-dialog>
  <file-upload
    v-else
    class="pointer"
    style="width: 100%; min-height: 100px"
    :multiple="false"
    :drop="true"
    :drop-directory="false"
    accept="image/*"
    v-model="file"
    ref="upload"
    :disabled="edit == false"
    @input-file="inputFile"
  >
    <div
      class="upload"
      :style="edit == false ? 'border: dashed var(--v-gris1-base) 3px' : ''"
    >
      <div v-if="file.length">
        <v-chip
          outlined
          color="primary"
          style="vertical-align: middle; margin-top: 6px"
          class="no-wrap mx-1"
          >{{ file[0].name }}</v-chip
        >
      </div>
      <div v-else style="width: 100%">
        <h4
          class="message"
          style="margin: auto"
          :style="edit == false ? 'color: var(--v-gris1-base)' : ''"
        >
          <v-icon :color="edit == false ? 'gris1' : 'primary'" size="14"
            >$clip</v-icon
          >
          {{ $t("designs.drop") }}
        </h4>
      </div>

      <div class="example-btn"></div>
    </div>
  </file-upload>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  props: ["edit"],
  data() {
    return { dialog: false, file: [] };
  },
  components: { FileUpload },

  methods: {
    ...mapActions("auth", ["imageUpdate"]),
    ...mapActions("users", ["deleteSetting"]),
    inputFile(newFile, oldFile) {
      if (newFile) {
        let formData = new FormData();
        formData.append("photo", newFile.file);
        this.imageUpdate(formData).then((response) => {
          this.dialog = false;
          this.file = [];
        });
      }
    },
    deleteFile() {
      this.deleteSetting("profile_image").then((response) => {
        this.dialog = false;
        this.file = [];
      });
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.state.auth.user;
      },
      set(value) {
        //console.log(value);
      },
    },
  },
};
</script>


<style lang="sass" scoped>
.profile_img
  .v-image
    display: flex
    justify-content: center
    align-items: center
    #camera
      opacity: 0

  &:hover
    background: rgba(0,0,0,0.8) !important
    opacity: 0.8 !important
  &:hover #camera, &:hover #delete
    opacity: 1
#delete
  right: 0px !important
  top: 8px !important
  position: absolute !important
  opacity: 0

#cardPerfil
  .headline
    span
      font-size: 24px !important
      font-weight: 500 !important
      line-height: 2rem
      letter-spacing: normal !important
      font-family: "Roboto", sans-serif !important

.upload
  min-height: 120px
  border-radius: 10px
  border: dashed var(--v-primary-base) 3px
  text-align: center
  display: flex
  align-items: center
  cursor: pointer
  width: 100%
  >div
    margin: auto
  .message
    font-weight: normal
    padding-top: 12px
    color: var(--v-primary-base)
</style>
<style lang="sass">
.file-uploads
  label
    height: 100% !important
</style>